import React, { useMemo, useState } from "react";
import { TawreedForm, TawreedFormSubmitProps } from "../../../../../../common/components/form";
import { OrderDto, OrderItemDto, orderService as service } from "../../../domain";
import { OrderFormItems } from "../order-form-items";
import { GlobalContext } from "../../../../../../context";
import { TawreedAction, TawreedActionName } from "../../../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { OrderFormSummary } from "../order-form-summary";
import { OrderDocumentTable } from "../order-document-table";
import * as actions from "./actions";
import { OrderFormProps } from "./order-form.props";
import { OrderFormState } from "./order-form.state";
import { OrderFormInformation1 } from "./order-form-information-1";
import './order-form.scss';
import { AuthService, authService } from "../../../../../auth/domain";
import { PackagesDialog } from "./order-packages-popup";
import { tawreedTranslationService } from "../../../../../../common/translations";
import { Panel } from "primereact/panel";
import { FileService, TawreedFile } from "../../../../../upload";
import { OrderDeliverDocumentUploadDialog } from "./order-document-upload-dialog";
import { classNames } from "primereact/utils";
import { OrderNote } from "./order-note";
import { OrderNoteDialog } from "./order-note-dialog";
import { OrderStatus } from "../../../data";
import { OrderpackagesFilesDialog } from "./order-package-files-popup";
import { ShipmentPackage } from "../../../domain/dtos/ready-to-delivery";
import { useHistory } from "react-router-dom";
import { PublicOrderFormInformation } from "./public-order-form-information";

export type PublicOrderFormProps = {
    /**
     *
     */
    orderId?: string;

    /**
     *
     */
    lang?: string
};

export const PublicOrderForm: React.FC<PublicOrderFormProps> = ({ orderId, lang }) => {

    const userService: AuthService = useMemo(() => new AuthService(), []);
    const fileService: FileService = React.useMemo(() => new FileService(), []);

    const history = useHistory();
    // di
    const { constants: { constants }, auth: { user } } = React.useContext(GlobalContext);
    // states
    const [state, setState] = React.useState<OrderFormState>({
        loading: false,
        mode: 'Edit',
        form: {
            //
        },
        actions: [],
    });

    // React.useEffect(() => {
    //     setState(previous => {
    //         return { ...previous, actions: [] }
    //     });
    // }, [state.form, returned, listener]);

    React.useEffect(() => {
        let mounted = true;
        if (orderId) {
            if (mounted) {
                setState(previous => {
                    return { ...previous, loading: true, mode: 'Edit' }
                });
                service.getPublicDetails(orderId, lang!)
                    .then(res => {
                        setState(previous => {
                            return { ...previous, loading: false, form: res }
                        });
                    })
                    .catch(() => {
                        setState(previous => {
                            return { ...previous, loading: false }
                        });
                    });
            }
        } else {
            if (mounted) {
                setState(previous => {
                    return { ...previous, loading: false, mode: 'Create', form: {} }
                });
            }
        }
        return () => {
            mounted = false;
        };
    }, [orderId]);

    const submit: TawreedFormSubmitProps<OrderDto> = {
        showButton: false,
        resetOnSubmit: false,
    }

    return (
        <React.Fragment>
            <TawreedForm title={'lbl_sales_orders'}
                dataKey="orderId" data={state.form}
                submit={submit}
                loading={state.loading} mode={state.mode} actions={state.actions} useBack={undefined} className="grid form-grid">

                <div className="col-12">
                        <PublicOrderFormInformation salesOfferTitle={state.form.salesOfferTitle}
                         className="" returned={false} orderStatuses={constants?.orderStatuses} 
                         salesOrderId={state.form.salesOrderId} showShipment={state.form.showShipment} 
                         editShipment={state.form.editShipment} />
                    </div>

                <OrderFormItems
                    className="col-12"
                    returned={false}
                    disabled={state.loading}
                    showEdit={state.form.showEdit}
                    items={state.form.items}
                    showNote={state.form.showConfirm || false}
                    onSave={()=>{
                        return new Promise((resolve,reject) =>{
                            return false;
                        })
                    }} />

                <OrderFormSummary className="col-12"
                    returned={false}
                    subTotal={state.form.subTotal}
                    shippingTotal={state.form.shippingTotal}
                    discount={state.form.totalDiscount}
                    total={state.form.total}
                    totalTax={state.form.totalTax}
                    grossTotal={state.form.grossTotal}
                />


            </TawreedForm>
            {/* <Panel header={tawreedTranslationService.translate('lbl_order_notes')} className="col-12">
                {
                    state.form.notes?.map((note) => {
                        return <React.Fragment key={note.salesOrderNoteId}>
                            <OrderNote salesOrderNoteId={note.salesOrderNoteId}
                                userName={note.userName} note={note.note} createdAt={note.createdAt}
                                partner={note.partner} email={note.email} mobileNo={note.mobileNo}
                                salesOrderId={note.salesOrderId} userId={note.userId}></OrderNote>
                            <br />
                        </React.Fragment>
                    })
                }
            </Panel> */}
            {/* <Panel header={tawreedTranslationService.translate('lbl_Doument_files')} className="col-12">
                <OrderDocumentTable data={state.form?.fiels ?? []} addDocumentFile={addOrderFile} downloadDocumentFile={downloadOrderFile} loading={state.loading}></OrderDocumentTable>
            </Panel> */}

        </React.Fragment>
    );
}
