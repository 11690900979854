import React from "react";
import { Panel } from "primereact/panel";
import { OverlayPanel } from "primereact/overlaypanel";
import { NavLink } from "react-router-dom";

import { tawreedTranslationService } from "../../../../../../common/translations";
import { TawreedFormField, TawreedInputPlain } from "../../../../../../common/components/form";
import { TawreedMenu } from "../../../../../../modules/constants";
import { Routes } from "../../../../../../router";
import { OrderFormPartnerInformation } from "../order-form-partner-information";
import { OrderFormHistory } from "./order-form-history";
import { GlobalContext } from "../../../../../../context";

export type PublicOrderFormInformationProps = {
    className: string;

    returned?: boolean;

    orderStatuses?: TawreedMenu;

    salesOrderId?: number;
    showShipment?: boolean;
    editShipment?: boolean;
    salesOfferTitle?: string;
};

export const PublicOrderFormInformation: React.FC<PublicOrderFormInformationProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);

    return (
        <React.Fragment>
            <Panel header={tawreedTranslationService.translate(`lbl_sales_orders_o_info`)} className={props.className}>
                <div className="formgrid grid">
                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="orderId" title={props.returned ? 'lbl_sales_orders_return_id' : 'lbl_sales_orders_order_id'}>
                            <TawreedInputPlain name="orderId" render="form" />
                        </TawreedFormField>
                    </div>
                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="orderDate" title="lbl_sales_orders_order_date">
                            <TawreedInputPlain name="orderDate" render="form" mode="datetime" />
                        </TawreedFormField>
                    </div>
                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="statusId" title="lbl_sales_orders_status">
                            <TawreedInputPlain name="status" render="form" className="text-primary cursor-pointer" />
                        </TawreedFormField>
                    </div>

                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="priceListItem.name" title="lbl_sales_orders_pricelistid">
                            <TawreedInputPlain name="priceListItem" nested="name" render="form-nested" mode="text" />
                        </TawreedFormField>
                    </div>

                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="paymentModeName" title="lbl_sales_orders_paymentmodeid">
                            <TawreedInputPlain name="paymentModeName" render="form" />
                        </TawreedFormField>
                    </div>

                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="customer.partnerName" title="lbl_sales_orders_customer">
                            <TawreedInputPlain name="customer" nested="partnerName" render="form-nested" mode="text" className="text-primary" />
                        </TawreedFormField>
                    </div>

                    <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="store.partnerName" title="lbl_sales_orders_store">
                            <TawreedInputPlain name="store" nested="partnerName" render="form-nested" mode="text" className="text-primary"/>
                        </TawreedFormField>
                    </div>
                    {
                        props.salesOfferTitle &&
                        <div className="col-12 sm:col-6 md:col-3">
                        <TawreedFormField className="field" name="salesOfferTitle" title="lbl_sales_orders_offer">
                            <TawreedInputPlain name="salesOfferTitle" render="form" mode="text" />
                        </TawreedFormField>
                    </div>

                    }
                    {
                        props.returned &&
                        props.salesOrderId &&
                        <div className="col-12 md:col-3">
                            <TawreedFormField title="lbl_sales_orders_order_id" className="field mb-0  w-full md:w-auto">
                                <TawreedInputPlain render="form" name="salesOrderId" />
                            </TawreedFormField>
                        </div>
                    }

                    {
                        !props.returned &&
                        props.showShipment &&
                        <div className="col-12 md:col-3">
                            <TawreedFormField title="lbl_sales_orders_carrier" className="field mb-0  w-full md:w-auto">
                                <TawreedInputPlain render="form-nested" name="carrier" nested="carrierName" className="text-primary"/>
                            </TawreedFormField>
                        </div>
                    }
                </div>
            </Panel>
        </React.Fragment>
    );
}
